.transaction-history-page {
  color: #fff;

  .title {
    @include flex(center, flex-start, row);
    margin-bottom: 2rem;

    h2.title {
      font-size: clamp(1.3rem, 4vw, 1.7rem);
    }
  }

  .table {
    border-radius: 5px;
    border: 1px solid $borderColor;
    min-height: 500px;
    width: 100%;

    .header {
      @include grid(2, 60% 40%, 0);
      border-bottom: 1px solid $borderColor;
      padding: 1rem 0.5rem 1rem 0.5rem;
      margin-bottom: 1rem;

      p {
        font-size: clamp(0.8rem, 2vw, 0.9rem);

        &.tab-up {
          display: none;
        }

        &.amount {
          text-align: end;
          padding-right: 1rem;
        }
      }
    }

    .transaction-details {
      @include flex(flex-start, center, column);
      gap: 10px;

      .item {
        @include grid(2, 60% 40%, 0);
        width: 100%;
        padding: 0.2rem 0.5rem;
        border-bottom: 1px solid $borderColor;

        p {
          font-size: clamp(0.8rem, 4vw, 1rem);
        }

        span {
          color: $lightGreyTextColor;
          font-size: clamp(0.7rem, 2vw, 0.9rem);
        }

        .details {
          @include flex(center, flex-start, row);
          gap: 10px;

          .icon {
            width: 28px;
            height: 28px;
            @include flex(center, center, row);
            border-radius: 50%;

            &.approved {
              color: $successColor;
              background-color: $successColorBG;
            }

            &.pending {
              color: $pendingColor;
              background-color: $pendingColorBG;
            }

            &.failed {
              color: $failedColor;
              background-color: $failedColorBG;
            }
          }
        }

        .amount {
          @include flex(center, center, column);
          gap: 5px;
        }

        .tab-up {
          display: none;
        }
      }
    }
  }

  .no-data {
    @include flex(center, center, row);
    min-height: 50vh;

    h2 {
      font-size: clamp(1.1rem, 4vw, 1.4rem);
    }
  }
}

// MODAL FOR TRANSACTION DETAILS
.transaction-details-modal {
  color: #fff;

  .mantine-Modal-header {
    background-color: $darkModeBg1;
  }

  .mantine-Modal-content {
    background-color: $darkModeBg1;

    .title {
      @include flex(center, flex-start, row);
      gap: 10px;
      margin-bottom: 1.5rem;

      h2 {
        font-size: clamp(1.5rem, 4vw, 1.9rem);
      }

      h3 {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: $primaryGreen;
        font-weight: 600;
      }
    }

    .type-amount {
      @include flex(center, flex-start, row);
      gap: 10px;
      margin-bottom: 1.5rem;

      .icon {
        width: 28px;
        height: 28px;
        @include flex(center, center, row);
        border-radius: 50%;

        &.approved {
          color: #fff;
          background-color: $successColor;
        }

        &.pending {
          color: #fff;
          background-color: $pendingColor;
        }

        &.failed {
          color: #fff;
          background-color: $failedColor;
        }
      }

      .info {
        h2 {
          font-size: clamp(1.2rem, 4vw, 1.4rem);
        }

        span {
          color: $lightGreyTextColor;
          font-size: clamp(0.7rem, 2vw, 0.9rem);
        }
      }
    }

    .txr-info,
    .txr-details {
      margin-bottom: 1.5rem;

      h2.title {
        margin-bottom: 1rem;
        font-size: clamp(1.1rem, 4vw, 1.2rem);
      }

      .items {
        @include grid(1, 1fr, 20px);

        .item {
          @include flex(flex-start, center, column);
          gap: 2px;

          h4 {
            font-size: clamp(0.8rem, 2vw, 0.9rem);
            color: $lightGreyTextColor;
          }

          p {
            font-size: clamp(0.9rem, 3vw, 1rem);

            &.trx-type,
            &.asset {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .transaction-history-page {
    .table {
      .header {
        @include grid(4, 40% 20% 20% 20%, 0);
        padding-left: 1rem;

        p {
          font-size: clamp(0.8rem, 2vw, 0.9rem);
          text-align: center;

          &.tab-up {
            display: block;
          }

          &.details {
            text-align: start;
          }

          &.amount {
            text-align: center;
            padding-right: 0;
          }
        }
      }

      .transaction-details {
        .item {
          @include grid(4, 40% 20% 20% 20%, 0);
          padding: 0.2rem 1rem;

          .tab-up {
            display: block;
          }

          .status {
            @include flex(center, center, row);

            p {
              padding: 0.2rem 0.8rem;
              text-transform: capitalize;
              border-radius: 5px;
              width: 100px;
              text-align: center;

              &.approved {
                color: $successColor;
                background-color: $successColorBG;
                border: 1px solid $successColor;
              }

              &.pending {
                color: $pendingColor;
                background-color: $pendingColorBG;
                border: 1px solid $pendingColor;
              }

              &.failed {
                color: $failedColor;
                background-color: $failedColorBG;
                border: 1px solid $failedColor;
              }
            }
          }

          .options {
            @include flex(center, center, row);

            .icon {
              border: 1px solid $borderColor;
              padding: 0.3rem;
              background-color: #fff;
              @include flex(center, center, row);
              border-radius: 5px;
              cursor: pointer;
              color: #000;

              svg {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  // MODAL FOR TRANSACTION DETAILS
  .transaction-details-modal {
    .mantine-Modal-content {
      .txr-info,
      .txr-details {
        .items {
          @include grid(2, 1fr, 20px);
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
