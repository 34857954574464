$primaryGreen: #23c99d;
$primaryBlue: #00004d;
$generalTextColor: #415076;
$lightGreyTextColor: #8094ae;
$lightGreyTextColor2: #a5b1bd;
$linear: linear-gradient(to right, #23c99d 0%, #16a3fe 100%);
$boxShadow1: 0 0 20px 0 rgba(91, 83, 162, 0.08);
$borderColor: #33383f;
$successColor: #1ee0ac;
$successColorBG: #e6fcf6;
$pendingColor: #f4bd0e;
$pendingColorBG: #fef8e4;
$failedColor: #e85347;
$failedColorBG: #fceceb;

$darkModeBg1: #1b2130;
$darkModeBg2: #1c2127;
