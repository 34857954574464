.regulations-page {
  .regulations-info {
    padding: 3rem 14px;

    .block {
      margin-bottom: 1.5rem;

      h3.title {
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        margin-bottom: 0.5rem;
      }

      p {
        font-size: clamp(0.9rem, 3vw, 1.1rem);
        opacity: 0.8;
        line-height: 26px;
      }

      ul {
        list-style-type: disc;
        padding-left: 24px;

        li {
          font-size: clamp(0.9rem, 2vw, 1.1rem);
          margin-bottom: 0.5rem;
          opacity: 0.8;
        }
      }
    }
  }
}

@include for-phone-only {
  .regulations-page {
    .regulations-info {
      padding: 3rem 24px;
    }

    .locations {
      padding: 2rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .regulations-page {
    .regulations-info {
      padding: 3rem 40px;
    }

    .locations {
      padding: 2rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .regulations-page {
    .regulations-info {
      padding: 3rem 60px;
    }

    .locations {
      padding: 2rem 60px;
    }
  }
}

@include for-desktop-up {
  .regulations-page {
    .regulations-info {
      padding: 3rem 80px;
    }

    .locations {
      padding: 2rem 80px;
    }
  }
}

@include for-big-desktop-up {
}
