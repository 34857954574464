.dashboard-page {
  .navbar-content {
    .content {
      margin-top: 68px;
      padding: 3rem 14px;
      background-color: #000000;
      min-height: 100vh;
      color: #fff;
    }
  }
}

@include for-phone-only {
  .dashboard-page {
    .navbar-content {
      .content {
        padding: 3rem 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .dashboard-page {
    .navbar-content {
      .content {
        padding: 3rem 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
  .dashboard-page {
    .navbar-content {
      .content {
        padding: 3rem 60px;
      }
    }
  }
}

@include for-desktop-up {
  .dashboard-page {
    @include flex(flex-start, space-between, row);
    height: 100vh;

    aside {
      width: 20%;
      height: 100%;
      box-shadow: $boxShadow1;
      background-color: $darkModeBg1;
    }

    .navbar-content {
      width: 80%;
      @include flex(flex-start, flex-start, column);
      height: 100%;

      nav {
        width: 100%;
        background-color: $darkModeBg1;
        border-bottom: 1px solid $borderColor;
      }

      .content {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        margin-top: 0;
        padding: 3rem 40px 5rem 40px;
        color: #fff !important;
        min-height: auto;
      }
    }
  }
}

@include for-big-desktop-up {
}
