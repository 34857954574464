.crypto-table {
  background-color: $darkModeBg1;
  border-radius: 5px;
  color: #fff;
  border-bottom: 1px solid rgb(38, 41, 50);

  .header {
    @include grid(3, 10% 65% 25%, 0);
    padding: 0.8rem 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgb(38, 41, 50);

    p {
      &.tab-up {
        display: none;
      }

      &:nth-child(3) {
        text-align: end;
      }
    }
  }

  .items {
    @include flex(center, center, column);
    gap: 10px;

    .item {
      width: 100%;
      @include grid(3, 10% 65% 25%, 0);
      padding: 0.5rem;

      p {
        font-size: clamp(0.9rem, 3vw, 1rem);

        &:nth-child(3) {
          text-align: end;
        }

        &.tab-up {
          display: none;
        }
      }

      .crypto {
        @include flex(center, flex-start, row);
        gap: 5px;

        .img {
          width: 32px;
          height: 32px;
        }

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
          font-weight: 700;
        }
      }
    }
  }
}

.trending-coins {
  margin-bottom: 4rem;

  h2.title {
    font-size: clamp(1.1rem, 3vw, 1.3rem);
    margin-bottom: 1.5rem;
    color: $lightGreyTextColor2;
  }

  .grid {
    @include grid(1, 1fr, 30px);

    .item {
      .icon-name {
        @include flex(center, flex-start, row);
        gap: 5px;
        padding: 0.5rem;
        margin-bottom: 1rem;

        .icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #fff;

          img {
            border-radius: 50%;
          }
        }

        h4 {
          font-size: clamp(1.1rem, 3vw, 1.2rem);
        }

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
          color: $lightGreyTextColor2;
          font-weight: 600;
        }
      }

      .chart-price {
        background-color: $darkModeBg1;
        border-radius: 5px;
        padding: 0.5rem;
        @include flex(center, space-between, row);
        gap: 10px;

        .chart {
          width: 65%;
          padding: 1rem 0;
        }

        .price {
          width: 35%;
          background-color: rgb(38, 43, 49);
          padding: 0.3rem 0.5rem;
          border-radius: 5px;

          p {
            font-size: clamp(1.1rem, 3vw, 1.3rem);
          }

          span {
            @include flex(center, flex-start, row);
            font-size: clamp(0.8rem, 2vw, 0.9rem);
            color: $successColor;
            gap: 5px;

            &.fall {
              color: $failedColor;
            }
          }
        }
      }
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .crypto-table {
    .header {
      @include grid(5, 5% 35% 20% 20% 20%, 0);
      padding: 0.8rem 1rem;

      p {
        &.tab-up {
          display: block;
        }

        &:nth-child(3) {
          text-align: start;
        }

        &:last-child {
          text-align: end;
        }
      }
    }

    .items {
      @include flex(center, center, column);
      gap: 10px;

      .item {
        width: 100%;
        @include grid(5, 5% 35% 20% 20% 20%, 0);
        padding: 0.5rem 1rem;

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);

          &:nth-child(3) {
            text-align: start;
          }

          &:last-child {
            text-align: end;
          }

          &.tab-up {
            display: block;
          }
        }

        .crypto {
          @include flex(center, flex-start, row);
          gap: 5px;

          .img {
            width: 32px;
            height: 32px;
          }

          p {
            font-size: clamp(0.9rem, 3vw, 1rem);
            font-weight: 700;
          }
        }
      }
    }
  }

  .trending-coins {
    .grid {
      @include grid(2, 1fr, 30px);
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .trending-coins {
    .grid {
      @include grid(3, 1fr, 30px);
    }
  }
}

@include for-big-desktop-up {
}
