.referral-program-page {
  color: #fff;

  .title {
    @include flex(flex-start, center, column);
    margin-bottom: 2rem;
    gap: 5px;

    h2 {
      font-size: clamp(1.8rem, 4vw, 2.5rem);
    }

    p {
      color: $lightGreyTextColor;
      font-size: clamp(0.9rem, 3vw, 1rem);
    }
  }

  .steps-guide {
    @include grid(1, 1fr, 30px);
    margin-bottom: 4rem;

    .step {
      @include flex(center, center, column);
      text-align: center;

      .icon {
        border-radius: 50%;
        padding: 1rem;
        @include flex(center, center, row);
        background-color: rgba(35, 201, 157, 0.3);
        margin-bottom: 0.5rem;

        svg {
          font-size: 1.3rem;
          color: $primaryGreen;
        }
      }

      h3 {
        font-size: clamp(1.3rem, 3vw, 1.5rem);
        margin-bottom: 0.3rem;
      }

      p {
        font-size: clamp(0.8rem, 2vw, 0.9rem);
        color: $lightGreyTextColor;
        max-width: 250px;
      }
    }
  }

  .share-the-link {
    h2 {
      font-size: clamp(1.2rem, 3vw, 1.4rem);
    }

    p.brief {
      font-size: clamp(0.8rem, 2vw, 0.9rem);
      color: $lightGreyTextColor;
      margin-bottom: 1rem;
    }

    .link {
      border-radius: 30px;
      background-color: rgba(35, 201, 157, 0.2);
      @include flex(center, space-between, row);
      padding: 1rem 1.5rem;
      max-width: 500px;

      p {
        font-size: clamp(0.8rem, 2vw, 0.9rem);
        color: $lightGreyTextColor;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
      }

      span {
        font-size: clamp(0.8rem, 2vw, 0.9rem);
        color: $primaryGreen;
      }
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .referral-program-page {
    .steps-guide {
      @include grid(2, 1fr, 30px);

      .step {
        &:last-child {
          grid-column: 1/3;
          margin: 0 auto;
        }
      }
    }

    .share-the-link {
      margin-top: 2rem;
    }
  }
}

@include for-tablet-landscape-up {
  .referral-program-page {
    .steps-guide {
      @include grid(3, 1fr, 30px);
      margin: 5rem 0 8rem 0;

      .step {
        &:last-child {
          grid-column: auto;
        }
      }
    }
  }
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
