.auth-page {
  padding: 2rem 14px;
  @include flex(center, center, column);
  min-height: 100vh;

  form {
    box-shadow: $boxShadow1;
    border-radius: 5px;
    padding: 2rem 14px;
    width: 100%;
    max-width: 600px;
    margin: 2rem;
    background-color: #fff;

    h2.title {
      font-size: clamp(1.2rem, 4vw, 1.6rem);
      text-align: center;
      margin-bottom: 1rem;
    }

    .form-control {
      .country-select {
        width: 100%;

        &:focus {
          border: none;
        }

        .css-5kkxb2-requiredInput-RequiredInput {
          display: none;
        }
      }

      .goal-select {
        width: 100%;
        font-family: 'Kanit', sans-serif !important;

        &::placeholder {
          font-family: 'Kanit', sans-serif !important;
        }
      }
    }

    .btn {
      margin-bottom: 1.5rem;
      @include flex(center, center, row);

      button {
        width: 100%;
      }
    }

    .existing-account,
    .new-to-the-platform,
    .dont-want-to-reset-password {
      h4 {
        font-weight: 400;
        margin-bottom: 0.5rem;

        a {
          color: $primaryGreen;
          margin-left: 0.2rem;
          font-weight: 500;
        }
      }

      p {
        font-size: clamp(0.8rem, 2vw, 0.9rem);

        a {
          color: $primaryGreen;
          margin-left: 0.2rem;
          font-weight: 500;
        }
      }
    }
  }

  // verify email page
  .verified {
    width: 100%;
    max-width: 600px;
    margin: 2rem;
    @include flex(center, center, column);
    text-align: center;
    gap: 10px;

    .check-email-img {
      max-width: 300px;
    }

    h2 {
      font-size: clamp(2rem, 6vw, 3.5rem);
    }

    p {
      font-size: clamp(1rem, 3vw, 1.1rem);
      color: $generalTextColor;
    }

    button {
      border-radius: 15px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .img {
      width: 62px;
      height: 62px;
    }
  }
}

@include for-phone-only {
  .auth-page {
    padding: 2rem 24px;

    form {
      padding: 2rem 14px;
    }
  }
}

@include for-tablet-potrait-up {
  .auth-page {
    padding: 2rem 40px;

    form {
      padding: 2rem 24px;

      .btn {
        button {
          width: 60%;
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .auth-page {
    padding: 2rem 60px;

    form {
      padding: 2rem 24px;

      .btn {
        button {
          width: 60%;
        }
      }
    }
  }
}

@include for-desktop-up {
  .auth-page {
    padding: 2rem 80px;
  }
}

@include for-big-desktop-up {
}
