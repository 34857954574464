.home-page {
  // header
  .header {
    padding: 8rem 14px 3rem 14px;
    background-image: url('../../../assets/images/home-header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    min-height: 100vh;
    // max-height: 800px;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
    }

    .info {
      position: relative;
      color: #fff;
      z-index: 3;

      h1 {
        font-size: clamp(1.8rem, 4vw, 3.5rem);
        margin-bottom: 0.5rem;
      }

      p {
        font-size: clamp(1rem, 4vw, 1.5rem);
        opacity: 0.6;
        line-height: 32px;
        font-weight: 500;
      }

      .btns {
        margin-top: 3rem;
        @include flex(center, flex-start, row);
        flex-wrap: wrap;
        gap: 20px;

        button.trans {
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }

  // hero
  .hero {
    @include flex(center, center, column);
    gap: 30px;
    padding: 5rem 14px;

    .info {
      h2 {
        font-size: clamp(1.5rem, 4vw, 2.5rem);
        margin-bottom: 1rem;
        max-width: 600px;
      }
    }

    .image {
      height: 350px;
      width: 100%;
      border-radius: 10px;
      position: relative;

      .info {
        height: 100%;
        width: 100%;
        @include flex(center, flex-start, row);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .assets {
          position: relative;
          @include flex(flex-start, center, column);
          gap: 20px;
          padding: 0 1rem;

          .asset {
            @include flex(center, flex-start, row);
            gap: 10px;
            background: hsla(0, 0%, 96%, 0.541) 0 0 no-repeat padding-box;
            border: 1px solid #ebebeb;
            border-radius: 10px;
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.047);
            padding: 11px 8px;
            min-width: 200px;

            .icon {
              width: 40px;
              height: 40px;
            }

            h3 {
              font-size: clamp(1.1rem, 4vw, 1.3rem);
              color: #282828;
            }
          }
        }
      }

      .img {
        border-radius: 10px;
        height: 100%;

        img {
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }

  // investments
  .investments {
    padding: 3rem 14px 5rem 14px;
    @include flex(center, center, column);
    background-color: #fff;

    .title {
      text-align: center;
      margin-bottom: 2.5rem;

      h4 {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: $primaryGreen;
      }

      h2 {
        font-size: clamp(1.4rem, 4vw, 2.5rem);
      }
    }

    .grid {
      @include grid(1, 1fr, 30px);

      .card {
        background-color: #f5f5f5;
        border-radius: 10px;
        @include flex(center, space-between, column);

        .info {
          padding: 1.5rem 1.5rem 0 1.5rem;

          h2 {
            font-size: clamp(1.5rem, 4vw, 2rem);
            margin-bottom: 1rem;
          }

          p {
            font-size: clamp(0.9rem, 3vw, 1rem);
            margin-bottom: 1.5rem;
            opacity: 0.6;
            line-height: 26px;
          }
        }

        .img {
          @include flex(center, center, row);
        }
      }
    }
  }

  // compliance and protection
  .compliance-protection {
    padding: 5rem 14px 5rem 14px;
    @include flex(center, center, column);
    background-color: #f2f2f4;

    .title {
      text-align: center;
      margin-bottom: 2.5rem;

      h4 {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: $primaryGreen;
      }

      h2 {
        font-size: clamp(1.4rem, 4vw, 2.5rem);
      }
    }

    .grid {
      @include grid(1, 1fr, 20px);

      .item {
        background-color: #fff;
        margin-bottom: 24px;
        border-radius: 8px;
        padding: 24px;

        .title-icon {
          @include flex(center, space-between, row);
          margin-bottom: 1rem;
          gap: 10px;

          h3 {
            font-size: clamp(1.1rem, 3vw, 1.4rem);
            font-weight: 500;
          }

          svg {
            font-size: 2rem;
          }
        }

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
          opacity: 0.6;
          line-height: 26px;
        }
      }
    }
  }

  // how it works
  .how-it-works {
    padding: 3rem 14px 5rem 14px;
    @include flex(center, center, column);

    .title {
      text-align: center;
      margin-bottom: 2.5rem;

      h4 {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: $primaryGreen;
      }

      h2 {
        font-size: clamp(1.4rem, 4vw, 2.5rem);
      }
    }

    .steps {
      @include flex(center, center, column);
      gap: 40px;

      .item {
        @include flex(flex-start, space-between, row);

        .number {
          width: 15%;
          @include flex(center, center, column);
          height: 80px;

          .line {
            width: 3px;
            height: 70%;
            background-color: $primaryGreen;
          }
        }

        .info {
          width: 80%;

          h3 {
            font-size: clamp(1.3rem, 4vw, 1.5rem);
            margin-bottom: 0.5rem;
          }

          p {
            font-size: clamp(0.9rem, 3vw, 1rem);
            margin-bottom: 1rem;
            line-height: 26px;
            opacity: 0.6;
          }
        }
      }
    }
  }
}

@include for-phone-only {
  .home-page {
    // header
    .header {
      padding: 8rem 24px 3rem 24px;
    }

    // hero
    .hero {
      padding: 5rem 24px;
    }

    // investments
    .investments {
      padding: 3rem 24px 5rem 24px;
    }

    // compliance and protection
    .compliance-protection {
      padding: 5rem 24px 5rem 24px;
    }

    // how it works
    .how-it-works {
      padding: 3rem 24px 5rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .home-page {
    // header
    .header {
      padding: 8rem 40px 3rem 40px;

      .info {
        max-width: 600px;
      }
    }

    // hero
    .hero {
      padding: 5rem 40px;
    }

    // investments
    .investments {
      padding: 3rem 40px 5rem 40px;

      .grid {
        @include grid(2, 1fr, 30px);
      }
    }

    // compliance and protection
    .compliance-protection {
      padding: 5rem 40px 5rem 40px;

      .grid {
        @include grid(2, 1fr, 20px);
      }
    }

    // how it works
    .how-it-works {
      padding: 3rem 40px 5rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .home-page {
    // header
    .header {
      padding: 8rem 60px 3rem 60px;
    }

    // hero
    .hero {
      @include flex(center, space-between, row);
      padding: 5rem 60px;

      .info {
        width: 50%;
      }

      .image {
        width: 50%;
        height: 650px;
      }
    }
    // investments
    .investments {
      padding: 3rem 60px 5rem 60px;

      .grid {
        @include grid(3, 1fr, 30px);
      }
    }

    // compliance and protection
    .compliance-protection {
      padding: 5rem 60px 5rem 60px;

      .grid {
        @include grid(3, 1fr, 20px);
      }
    }

    // how it works
    .how-it-works {
      padding: 8rem 60px 8rem 60px;

      .steps {
        gap: 80px;

        .item {
          @include flex(flex-start, flex-start, row);

          &.reverse {
            @include flex(flex-start, flex-start, row-reverse);
            text-align: end;
          }

          .number {
            height: 150px;
          }

          .info {
            width: 50%;
          }
        }
      }
    }
  }
}

@include for-desktop-up {
  .home-page {
    // header
    .header {
      padding: 10rem 80px 3rem 80px;
      max-height: 800px;

      .info {
        max-width: 900px;
      }
    }

    // compliance and protection
    .compliance-protection {
      padding: 8rem 80px 8rem 80px;
    }

    // investments
    .investments {
      padding: 3rem 80px 5rem 80px;
    }
  }
}

@include for-big-desktop-up {
  .home-page {
    // header
    .header {
      min-height: 800px;
    }
  }
}
