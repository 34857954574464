@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import './variables';
@import './mixins';
@import './dashboard-styles';
@import './not-found';
@import './auth';
@import './modals';

// COMPONENTS
@import '../components/footer/footer';
@import '../components/navbar/navbar';
@import '../components/newsletter/newsletter';
@import '../components/page-header/page-header';
@import '../components/trade-with-us/trade-with-us';
@import '../components/customers-reviews/customers-reviews';
@import '../components/faq/faq';

// DASHBOARD COMPONENTS
@import '../components/dashboard-components/navbar/dashboard-navbar';
@import '../components/dashboard-components/sidebar/sidebar';
@import '../components/dashboard-components/crypto-comp/crypto-comp';
@import '../components/dashboard-components/account-settings-tabs/account-settings-tabs';
@import '../components/dashboard-components/skeleton-loaders/skeleton-loaders';
@import '../components/dashboard-components/balance-summary/balance-summary';

// PAGES
@import '../pages/regular/home/home';
@import '../pages/regular/about-us/about-us';
@import '../pages/regular/contact-us/contact-us';
@import '../pages/regular/reviews/reviews';
@import '../pages/regular/regulations/regulations';
@import '../pages/regular/privacy-policy/privacy-policy';
@import '../pages/regular/terms-of-service/terms-of-service';

// DASHBOARD PAGES
@import '../pages/dashboard/overview/overview';
@import '../pages/dashboard/deposit/deposit';
@import '../pages/dashboard/withdrawal/withdrawal';
@import '../pages/dashboard/profile/profile';
@import '../pages/dashboard/transaction-history/transaction-history';
@import '../pages/dashboard/trading-plans/trading-plans';
@import '../pages/dashboard/kyc/kyc';
@import '../pages/dashboard/referral-program/referral-program';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  background-size: 100%;
  background-position: center;
  max-width: 1800px;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: 'Kanit', sans-serif !important;
  font-weight: 400;
  color: $generalTextColor;
  background-color: #f4f4f4;
  // font-family: 'Montserrat', sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: center;
}

button,
a.btn {
  @include flex(center, center, row);
  background-color: transparent;
  gap: 5px;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  padding: 6px 24px;
  cursor: pointer;
  font-family: 'Kanit', sans-serif;
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  letter-spacing: 0.02rem;
  transition: 0.2s linear opacity;

  img {
    height: 24px;
    width: 24px;
  }

  svg {
    font-size: 1.4rem;
    font-family: 600;
  }

  &:hover {
    opacity: 0.8;
  }

  &.green {
    background-color: $primaryGreen;
    color: #fff;
  }

  &.transparent {
    background: transparent;
    border: 1px solid $primaryGreen;
  }

  &.linear {
    background-image: linear-gradient(to right, #23c99d 0%, #16a3fe 100%);
    color: #fff;
  }

  &.dark-blue {
    background-color: #415076;
    color: #fff;
  }

  &.red {
    background-color: #f03e3d;
    color: #fff;
  }
}

.logo {
  width: 200px;

  img {
    object-fit: scale-down;
  }
}

// CUSTOM INPUT FIELDS

.form-row {
  @include for-tablet-potrait-up {
    @include flex(center, space-between, row);

    .form-control {
      width: 48%;
    }
  }
}

.form-control {
  @include flex(flex-start, center, column);
  margin-bottom: 1rem;

  label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;

    &.error {
      color: #f03e3d;
    }

    span {
      color: red;
    }
  }

  input {
    height: 100%;
    padding: 12px 18px;
    font-size: 16px;
    outline: none;
    border-radius: 4px;
    border: 1px solid #000;
    width: 100%;
    position: relative;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: inherit;
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: #4e6a8b;
    }
  }

  textarea {
    width: 100%;
    resize: none;
    min-height: 200px;
    border: 1px solid #000;
    border-radius: 30px;
    padding: 16px;
    outline: none;
  }

  select {
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 16px;
    font-size: 16px;
    outline: none;
    margin-top: 5px;
    background: transparent;
  }

  .password-cont {
    width: 100%;
    border: 1px solid #000;
    position: relative;
    border-radius: 4px;

    input {
      border: none;
      height: 100%;
      margin: 0;
      padding-left: 14px;
    }

    svg {
      position: absolute;
      right: 14px;
      transform: translateY(80%);
      color: #4e6a8b;
      cursor: pointer;
    }
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  color: #fff !important;
}

// RESET INPUT FONT-SIZE FOR MOBILE
@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}

.page-loading {
  @include flex(center, center, row);
  min-height: 50vh;

  img {
    width: 42px;
    height: 42px;
  }
}

.tradingview-widget-copyright {
  display: none !important;
}
