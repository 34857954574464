.mantine-Modal-root {
  color: #fff;

  .mantine-Modal-content {
    background-color: $darkModeBg1;

    .btn {
      @include flex(center, center, row);
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }

  .mantine-Modal-header {
    min-height: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    background-color: $darkModeBg1;
    margin-bottom: 1rem;

    .mantine-Modal-title {
      font-size: clamp(1.1rem, 3vw, 1.3rem);
      font-weight: 600;
    }

    button {
      @include flex(center, center, row);
      outline: none;

      &:focus {
        outline: none;
      }

      svg {
        margin: 0;
        color: #fff;
      }
    }
  }
}

// deposit && withdrawal successful modal
.transaction-successful-modal {
  text-align: center;
  .icon {
    @include flex(center, center, row);
    font-size: clamp(5rem, 10vw, 7rem);
    color: $successColor;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: clamp(1.3rem, 3vw, 1.5rem);
    margin-bottom: 1rem;
  }

  p {
    font-size: clamp(0.9rem, 3vw, 1rem);
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .btn {
    @include flex(center, center, row);
    margin-bottom: 2.5rem;
  }
}

// notification modal
.notification-modal {
  .mantine-Modal-content {
    h2.title {
      text-align: center;
      margin-bottom: 2rem;
    }

    .conent {
      margin-bottom: 3rem;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
