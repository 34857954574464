.reviews-page {
  .header {
    text-align: center;
    padding: 3rem 14px;

    h2.title {
      margin-bottom: 1rem;
      font-size: clamp(1.2rem, 3vw, 1.8rem);
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1.1rem);
      margin-bottom: 1rem;
    }
  }

  .reviews {
    padding: 3rem 14px;
  }
}

@include for-phone-only {
  .reviews-page {
    .header {
      padding: 3rem 24px;
    }

    .reviews {
      padding: 3rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .reviews-page {
    .header {
      padding: 3rem 40px;
    }

    .reviews {
      padding: 3rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .reviews-page {
    .header {
      padding: 3rem 60px;
    }

    .reviews {
      padding: 3rem 60px;
    }
  }
}

@include for-desktop-up {
  .reviews-page {
    .header {
      padding: 3rem 80px;
    }

    .reviews {
      padding: 3rem 80px;
    }
  }
}

@include for-big-desktop-up {
}
