.navbar {
  padding: 0.5rem 14px;
  @include flex(center, space-between, row);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
  transition: 0.3s linear background-color;
  color: #fff;
  max-width: 1800px;
  margin: 0 auto;

  &.fixed {
    background-color: #fff;
    color: inherit;
    box-shadow: $boxShadow1;
  }

  .links {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(11, 12, 16, 0.7);
    transform: translateX(-100%);
    transition: 0.2s transform;
    color: $generalTextColor;

    &.active {
      transform: translateX(0%);
      z-index: 20;
    }

    ul {
      background-color: #fff;
      height: 100%;
      padding: 2rem 14px;
      width: 70%;

      li {
        margin: 0 0 1.5rem 0;

        &:hover {
          color: $primaryGreen;
          cursor: pointer;
        }
      }

      .btns {
        width: 100%;

        a {
          margin-bottom: 1rem;
          width: 100%;

          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 17px;
      right: 14px;
      background-color: #fff;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      @include flex(center, center, row);

      svg {
        font-size: 1.5rem;
      }
    }
  }

  .hamburger {
    @include flex(center, flex-start, row);
    position: relative;

    svg {
      font-size: 1.8rem;
      font-weight: 400;
    }
  }
}

@include for-phone-only {
  .navbar {
    padding: 0.5rem 24px;

    .links {
      ul {
        padding: 2rem 24px;
      }

      .close-btn {
        right: 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .navbar {
    padding: 0.5rem 40px;

    .links {
      ul {
        width: 50%;
      }

      .close-btn {
        right: 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
  .navbar {
    padding: 0.5rem 60px;

    .links {
      ul {
        width: 35%;
      }

      .close-btn {
        right: 60px;
      }
    }
  }
}

@include for-desktop-up {
  .navbar {
    padding: 1rem 80px;

    .links {
      @include flex(center, space-between, row);
      position: static;
      height: auto;
      width: auto;
      background: transparent;
      transform: translateX(0);
      color: inherit;

      ul {
        @include flex(center, space-between, row);
        padding: 0;
        width: auto;
        background: transparent;

        li {
          margin: 0 1.5rem 0 0;
        }

        .btns {
          @include flex(center, space-between, row);
          margin-left: 1rem;
          width: auto;

          a {
            margin-bottom: 0;
            width: auto;

            &:first-child {
              margin-right: 1rem;
            }
          }
        }
      }

      .close-btn {
        display: none;
      }
    }

    .hamburger {
      display: none;
    }
  }
}

@include for-big-desktop-up {
}
