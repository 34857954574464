.footer {
  position: relative;
  padding: 0 14px 3rem 14px;
  color: #fff;
  border: 2px solid $primaryBlue;
  background-color: $primaryBlue;
  margin-top: 100px;

  .payment-methods {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -12%;
    @include grid(2, 1fr, 10px);
    background-color: #fff;
    width: 70%;
    padding: 1rem 1rem;
    box-shadow: 0 5px 50px rgba(91, 83, 162, 0.25);
    border-radius: 6px;

    .img {
      @include flex(center, center, row);

      img {
        height: auto;
        width: 80%;
      }
    }
  }

  .links {
    margin-top: 100px;
    @include grid(2, 1fr, 20px);
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(80, 79, 79);

    .item {
      &:first-child {
        grid-column: 1/3;
      }

      ul {
        @include flex(flex-start, flex-start, column);

        li {
          margin-bottom: 1rem;
          cursor: pointer;
          display: inline-block;
          font-size: clamp(0.8rem, 2vw, 1rem);

          &:hover {
            color: $primaryGreen;
          }
        }
      }
    }
  }

  .copyright {
    margin-top: 2rem;

    h3 {
      font-weight: 500;
      font-size: clamp(0.9rem, 3vw, 1rem);
    }
  }
}

@include for-phone-only {
  .footer {
    padding: 0 24px 3rem 24px;

    .links {
      margin-top: 130px;
    }
  }
}

@include for-tablet-potrait-up {
  .footer {
    padding: 0 40px 3rem 40px;

    .payment-methods {
      top: -10%;
      @include grid(4, 1fr, 10px);
    }

    .links {
      @include grid(3, 1fr, 20px);

      .item {
        &:first-child {
          grid-column: 1/4;
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .footer {
    padding: 0 60px 3rem 60px;

    .payment-methods {
      top: -13%;
    }
  }
}

@include for-desktop-up {
  .footer {
    padding: 0 80px 3rem 80px;

    .payment-methods {
      width: 50%;
    }

    .links {
      @include grid(4, 1fr, 20px);

      .item {
        &:first-child {
          grid-column: 1/1;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
