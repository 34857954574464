.kyc-page {
  @include flex(center, flex-start, column);
  color: #fff;

  .title {
    margin-bottom: 2rem;
    text-align: center;

    h3 {
      font-size: clamp(1.1rem, 3vw, 1.3rem);
      border-bottom: 1px solid $primaryGreen;
      margin-bottom: 1rem;
      display: inline-flex;
      padding: 0 1rem 0.1rem 1rem;
    }

    h2 {
      font-size: clamp(1.3rem, 4vw, 1.7rem);
      text-align: center;
    }
  }

  .kyc-approved {
    @include flex(center, center, column);
    gap: 20px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    h1 {
      font-size: clamp(1.3rem, 4vw, 1.5rem);
    }

    p {
      color: $lightGreyTextColor;
    }
  }

  form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .mantine-Select-root {
      margin-bottom: 1.5rem;

      .mantine-Select-label {
        margin-bottom: 0.5rem;
        font-size: 14px;
      }

      .mantine-Select-wrapper {
        .mantine-Select-input {
          background-color: transparent;
          color: #fff;
          border: 2px solid $borderColor;
        }
      }

      .mantine-Select-dropdown,
      .mantine-Select-options {
        border: 2px solid green;
      }
    }

    .form-control {
      input {
        background-color: transparent;
        color: #fff;
        border: 2px solid $borderColor;
      }
    }

    .crypto-address {
      margin: 2rem 0;
      .qr-code {
        // height: 200px;
        width: 100%;
        max-width: 300px;
        margin: 0 auto 1.5rem auto;
      }

      .address {
        @include flex(center, center, column);
        flex-wrap: wrap;
        gap: 5px;

        h2 {
          font-size: clamp(1rem, 3vw, 1.1rem);
        }

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
          flex-wrap: wrap;
        }
      }
    }

    .upload-receipt {
      margin-bottom: 2rem;
      .mantine-Dropzone-root {
        margin-bottom: 2rem;
        background-color: transparent;
        color: #fff;
        border: 1px solid dotted;

        .mantine-Dropzone-inner {
          .upload {
            @include flex(center, center, row);
            gap: 10px;

            text-align: center;
            svg {
              font-size: 5rem;
            }

            p {
              font-size: clamp(0.9rem, 3vw, 1rem);
            }
          }
        }
      }
    }

    .btn {
      @include flex(center, center, row);
    }
  }
}

.deposit-successful-modal {
  text-align: center;
  .icon {
    @include flex(center, center, row);
    font-size: clamp(5rem, 10vw, 7rem);
    color: $successColor;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: clamp(1.3rem, 3vw, 1.5rem);
    margin-bottom: 1rem;
  }

  p {
    font-size: clamp(0.9rem, 3vw, 1rem);
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .btn {
    @include flex(center, center, row);
    margin-bottom: 2.5rem;
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
