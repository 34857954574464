.privacy-policy-page {
  .privacy-info {
    padding: 3rem 14px;

    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.8rem);
      text-align: center;
      margin-bottom: 1rem;
    }

    .block {
      margin-bottom: 1.5rem;

      h3.title {
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        margin-bottom: 0.5rem;
      }

      p {
        font-size: clamp(0.9rem, 3vw, 1.1rem);
        opacity: 0.8;
        line-height: 26px;
      }

      ul,
      ol {
        list-style-type: disc;
        padding-left: 24px;

        li {
          font-size: clamp(0.9rem, 2vw, 1.1rem);
          margin-bottom: 1rem;
          opacity: 0.8;
        }
      }

      ol {
        list-style-type: lower-alpha;
      }
    }
  }
}

@include for-phone-only {
  .privacy-policy-page {
    .privacy-info {
      padding: 3rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .privacy-policy-page {
    .privacy-info {
      padding: 3rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .privacy-policy-page {
    .privacy-info {
      padding: 3rem 60px;
    }
  }
}

@include for-desktop-up {
  .privacy-policy-page {
    .privacy-info {
      padding: 3rem 80px;
    }
  }
}

@include for-big-desktop-up {
}
