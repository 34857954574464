.overview-page {
  color: #fff;

  // wallets
  .wallets {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .card {
      background: $primaryGreen;
      color: #fff;
      padding: 1rem 0.5rem;
      border-radius: 10px;
      position: relative;
      max-width: 400px;
      z-index: 1;

      &::before {
        position: absolute;
        content: '';
        background: $linear;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: -1;
        transform: rotate(8deg);
        opacity: 0.5;
        padding: 2rem;
      }

      &:after {
        position: absolute;
        content: '';
        background: $linear;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: -1;
        transform: rotate(4deg);
        opacity: 0.5;
        padding: 2rem;
      }

      h3 {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        font-size: clamp(1.1rem, 3vw, 1.3rem);
        font-weight: 500;
        position: relative;
      }

      .items {
        @include flex(flex-start, center, column);
        gap: 10px;
        margin-bottom: 1.5rem;
        position: relative;

        .item {
          width: 100%;
          @include flex(center, space-between, row);

          h4 {
            font-size: clamp(1.2rem, 3vw, 1.4rem);

            span {
              font-size: clamp(0.8rem, 2vw, 0.9rem);
            }
          }

          p {
            font-size: clamp(0.9rem, 3vw, 1rem);
          }
        }
      }

      .money-earned {
        position: relative;

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
        }
      }
    }
  }

  // balances
  .balances {
    margin-bottom: 4rem;

    .fiat-balance {
      margin-bottom: 3rem;

      .balance,
      .investment-goal {
        margin-bottom: 1.5rem;
        @include flex(center, flex-start, row);
        gap: 10px;

        .info {
          h3 {
            font-size: clamp(1.1rem, 4vw, 1.3rem);

            span {
              font-size: clamp(2rem, 4vw, 2.5rem);
            }
          }

          p {
            font-weight: 600;
            font-size: clamp(0.9rem, 3vw, 1rem);
            color: $lightGreyTextColor2;
          }
        }
      }

      .btns {
        @include flex(center, flex-start, row);
        gap: 20px;

        button {
          font-size: clamp(0.8rem, 2vw, 0.9rem);

          svg {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .overview-page {
    // wallets
    .wallets {
      .card {
        padding: 1rem;
      }
    }

    // balances
    .balances {
      .fiat-balance {
        .btns {
          @include flex(center, flex-end, row);
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
