.page-header {
    padding: 10rem 14px 5rem 14px;
    background-image: linear-gradient(to right, #122272 0%, #1940b0 100%);
    @include flex(center, center, column);
    color: #fff;

    .title {
        h1 {
            font-size: clamp(1.4rem, 6vw, 3.2rem);
            margin-bottom: 0.5rem;
            text-align: center;
        }
    }

    .info {
        width: 100%;

        p {
            font-size: clamp(0.8rem, 2vw, 1rem);
            text-align: center;
            margin-bottom: 1rem;
        }
    }

    .btn {
        margin-top: 0.5rem;
        padding: 1rem 3rem;
        font-size: clamp(0.9rem, 3vw, 1rem);
    }
}

@include for-phone-only {
    .page-header {
        padding: 10rem 24px 5rem 24px;
    }
}

@include for-tablet-potrait-up {
    .page-header {
        padding: 10rem 40px 5rem 40px;
    }
}

@include for-tablet-landscape-up {
    .page-header {
        padding: 10rem 60px 5rem 60px;
    }
}

@include for-desktop-up {
    .page-header {
        padding: 10rem 80px 5rem 80px;
    }
}

@include for-big-desktop-up {}