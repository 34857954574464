.trade-with-us {
  margin: 1rem 0;
  padding: 2rem 14px;
  @include grid(1, 1fr, 10px);

  .info {
    @include flex(flex-start, flex-start, column);
    margin-bottom: 1rem;

    h2 {
      font-size: clamp(1.5rem, 3vw, 2rem);
      margin-bottom: 0.5rem;
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1.1rem);
      margin-bottom: 1.5rem;
    }

    button {
      padding: 0.8rem 32px;
    }
  }
}

@include for-phone-only {
  .trade-with-us {
    padding: 2rem 24px;
  }
}

@include for-tablet-potrait-up {
  .trade-with-us {
    padding: 2rem 40px;

    .info {
    }
  }
}

@include for-tablet-landscape-up {
  .trade-with-us {
    padding: 2rem 40px;
    @include grid(2, 1fr, 10px);
    margin-bottom: 0;

    .info {
      @include flex(flex-start, center, column);
    }
  }
}

@include for-desktop-up {
  .trade-with-us {
    margin: 3rem 0;
    padding: 2rem 80px;
  }
}

@include for-big-desktop-up {
}
