.profile-page {
  color: #fff;

  .title {
    margin-bottom: 2rem;

    h4 {
      font-size: clamp(0.8rem, 3vw, 0.9rem);
      color: $lightGreyTextColor;
    }

    h2.title {
      font-size: clamp(1.3rem, 4vw, 1.7rem);
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
