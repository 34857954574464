.mobile-nav {
  padding: 0.5rem 14px;
  @include flex(center, space-between, row);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
  background-color: #24273a;
  color: inherit;
  box-shadow: $boxShadow1;

  .links {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(11, 12, 16, 0.7);
    transform: translateX(-100%);
    transition: 0.2s all;
    color: $generalTextColor;

    &.active {
      transform: translateX(0%);
      z-index: 20;
      transition: 0.2s all;
    }

    ul {
      background-color: #24273a;
      height: 100%;
      padding: 2rem 14px;
      width: 70%;
      overflow-y: scroll;

      li {
        font-size: clamp(0.8rem, 4vw, 1.3rem);
        padding: 0.8rem 14px;
        border-radius: 5px;
        margin-bottom: 1.5rem;
        @include flex(center, flex-start, row);
        color: #fff;
        text-transform: capitalize;

        &.active {
          background: $linear;
        }

        a {
          @include flex(center, flex-start, row);
          width: 100%;

          svg {
            font-size: 1.3rem;
            margin-right: 1rem;
          }
        }

        &.profile {
          font-size: clamp(1.2rem, 3vw, 1.5rem);
        }

        &.logout {
          margin-bottom: 100px;

          a {
            svg {
              font-size: 1.5rem;
              margin-right: 2rem;
            }
          }
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 17px;
      right: 14px;
      background-color: #fff;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      @include flex(center, center, row);

      svg {
        font-size: 1.5rem;
      }
    }
  }

  .hamburger {
    @include flex(center, flex-start, row);
    position: relative;
    color: #fff;

    svg {
      font-size: 1.8rem;
      font-weight: 400;
    }
  }
}

.desktop-nav {
  display: none;
}

@include for-phone-only {
  .mobile-nav {
    padding: 0.5rem 24px;

    .links {
      ul {
        padding: 2rem 24px;
      }

      .close-btn {
        right: 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .mobile-nav {
    padding: 0.5rem 40px;

    .links {
      ul {
        width: 50%;
      }

      .close-btn {
        right: 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
  .mobile-nav {
    padding: 0.5rem 60px;

    .links {
      ul {
        width: 35%;
      }

      .close-btn {
        right: 60px;
      }
    }
  }
}

@include for-desktop-up {
  .mobile-nav {
    display: none;
  }

  .desktop-nav {
    padding: 1rem 40px 1rem 0;
    box-shadow: $boxShadow1;
    @include flex(center, space-between, row);
    color: #fff;

    .widget {
      width: 80%;
    }

    .profile {
      @include flex(center, flex-start, row);
      gap: 5px;

      p {
        font-size: 1.1rem;
        text-transform: capitalize;
      }

      .circle {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        @include flex(center, center, row);
        cursor: pointer;
        border: 1px solid $borderColor;
      }

      .mantine-Menu-item {
        border: 2px solid green;
      }

      .mantine-Menu-dropdown {
        border: 2px solid red;
      }
    }
  }
}

@include for-big-desktop-up {
}
