.balance-summary {
  h3.title {
    color: $lightGreyTextColor2;
    font-size: clamp(1.1rem, 3vw, 1.3rem);
    margin-bottom: 1.5rem;
  }

  .grid {
    @include grid(1, 1fr, 30px);

    .item {
      @include flex(flex-start, center, column);
      background-color: $darkModeBg1;
      color: #fff;
      padding: 0.8rem 1rem;
      border-bottom: 1px solid rgb(38, 41, 50);
      border-radius: 10px;

      .crypto {
        @include flex(center, flex-start, row);
        gap: 5px;
        margin-bottom: 1rem;

        svg {
          font-size: 1.5rem;
          color: $primaryGreen;
        }

        p {
          font-size: clamp(1rem, 3vw, 1.1rem);
          font-weight: 600;
        }
      }

      .info {
        @include flex(center, flex-start, row);
        gap: 5px;
        font-weight: 600;

        p {
          font-size: clamp(1.1rem, 3vw, 1.3rem);
        }

        span {
          font-size: clamp(1.1rem, 3vw, 1.3rem);
          color: $lightGreyTextColor2;
        }
      }
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .balance-summary {
    .grid {
      @include grid(2, 1fr, 30px);

      .item {
        padding: 1.5rem 1rem;
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .balance-summary {
    .grid {
      @include grid(4, 1fr, 30px);
    }
  }
}

@include for-big-desktop-up {
}
