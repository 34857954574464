.account-settings-tabs {
  .tabs-container {
    overflow-x: scroll;
    scrollbar-width: none;
    margin-bottom: 3rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .tabs-list {
      @include flex(center, flex-start, row);
      width: 530px;

      li {
        margin-right: 1rem;
        font-size: clamp(0.9rem, 3vw, 1rem);
        cursor: pointer;
        padding-bottom: 0.5rem;

        &:hover {
          color: $primaryGreen;
          opacity: 0.5;
        }

        &.active {
          border-bottom: 2px solid $primaryGreen;

          &:hover {
            color: inherit;
            opacity: 1;
          }
        }
      }
    }
  }

  .tab-details {
    // personal information
    .personal-info {
      .my-profile {
        padding: 0.4rem 0.5rem;
        margin-bottom: 1.5rem;

        h5 {
          font-size: clamp(0.9rem, 3vw, 1rem);
        }
      }

      .info {
        @include flex(center, center, column);
        gap: 10px;
        margin-bottom: 50px;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        border: 1px solid $borderColor;

        .item {
          width: 100%;
          @include grid(2, 80% 20%, 0);
          transition: 0.3s linear all;
          cursor: pointer;
          border-bottom: 1px solid $borderColor;
          padding: 0.8rem 0;

          &:last-child {
            border: none;
          }

          &:hover {
            .arrow {
              .icon {
                background-color: $primaryGreen;
                color: #fff;
              }
            }
          }

          .text {
            h4 {
              font-size: clamp(0.9rem, 3vw, 1rem);
              margin-bottom: 3px;
              color: $lightGreyTextColor;
              font-weight: 500;
            }

            p {
              font-size: clamp(0.9rem, 3vw, 1rem);
              text-transform: capitalize;

              &.email {
                text-transform: lowercase;
              }
            }
          }

          .arrow {
            @include flex(center, flex-end, row);

            .icon {
              font-size: 1rem;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              @include flex(center, center, row);
              transition: 0.3s linear all;
            }
          }
        }
      }
    }

    // security information
    .security-info {
      .title {
        margin-bottom: 2rem;

        h2 {
          font-size: clamp(1.1rem, 3vw, 1.2rem);
        }
        p {
          font-size: clamp(0.8rem, 2vw, 0.9rem);
        }
      }

      .block {
        padding: 0.5rem 0;

        .item {
          border-bottom: 1px solid $borderColor;
          @include flex(flex-start, flex-start, column);
          gap: 10px;
          padding: 1rem 0.5rem;
          border: 1px solid $borderColor;
          border-radius: 10px;
          margin-bottom: 1.5rem;

          .info {
            h3 {
              font-size: clamp(0.9rem, 3vw, 1rem);
              margin-bottom: 3px;
            }
            p {
              font-size: clamp(0.8rem, 2vw, 0.9rem);
            }
          }
        }
      }
    }

    // notifications information
    .notifications-info {
      .block {
        padding: 0.5rem 0;

        .item {
          @include flex(flex-start, flex-start, column);
          gap: 10px;
          padding: 1rem 0.5rem;
          margin-bottom: 1.5rem;
          border: 1px solid $borderColor;
          border-radius: 10px;

          .title {
            margin-bottom: 1rem;

            h2 {
              font-size: clamp(1.1rem, 3vw, 1.2rem);
            }
            p {
              font-size: clamp(0.8rem, 2vw, 0.9rem);
            }
          }

          .notif {
            @include flex(flex-start, flex-start, row);
            gap: 10px;
            margin-bottom: 1rem;

            p {
              font-size: clamp(0.8rem, 2vw, 0.9rem);
            }
          }
        }
      }
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .account-settings-tabs {
    .tabs-container {
      .tabs-list {
        width: auto;
      }
    }

    .tab-details {
      .personal-info {
        .info {
          .item {
            @include grid(2, 60% 40%, 0);

            .text {
              @include flex(center, space-between, row);
            }
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .account-settings-tabs {
    .tab-details {
      .personal-info {
        .info {
          .item {
            @include grid(2, 50% 50%, 0);
          }
        }
      }
    }
  }
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
