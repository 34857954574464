.not-found-page {
  height: 100vh;
  @include flex(center, center, column);

  h1 {
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem);
    margin-bottom: 1rem;
  }

  button {
    padding: 0.8rem 32px;
    font-size: clamp(0.9rem, 3vw, 1.1rem);
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
