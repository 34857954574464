.trading-plans-page {
  color: #fff;

  .title {
    @include flex(center, flex-start, row);
    margin-bottom: 2rem;

    h2.title {
      font-size: clamp(1.3rem, 4vw, 1.7rem);
    }
  }

  .grid {
    @include grid(1, 1fr, 30px);

    .card {
      padding: 1.5rem 1rem;
      @include flex(center, center, column);
      background-color: $darkModeBg1;
      border-radius: 10px;

      .title {
        width: 100%;
        @include flex(center, center, column);
        gap: 5px;
        margin-bottom: 1rem;

        h3 {
          font-size: clamp(1.5rem, 4vw, 1.8rem);
        }

        h4 {
          font-size: clamp(0.8rem, 2vw, 0.9rem);
        }
      }

      .amount {
        @include flex(center, flex-start, row);
        gap: 2px;
        margin-bottom: 1rem;
        width: 100%;
        color: $successColor;
        font-size: clamp(1.4rem, 4vw, 1.8rem);
      }

      .list {
        @include flex(flex-start, center, column);
        gap: 10px;
        margin-bottom: 2rem;

        h3 {
          font-size: clamp(1.3rem, 4vw, 1.5rem);
        }

        ul {
          @include flex(flex-start, center, column);
          gap: 10px;

          li {
            font-size: clamp(0.8rem, 3vw, 0.9rem);
            @include flex(center, flex-start, row);
            gap: 5px;

            svg {
              font-size: clamp(0.8rem, 3vw, 0.9rem);
              color: $successColor;
            }
          }
        }
      }

      .btn {
        @include flex(center, center, row);
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .trading-plans-page {
    .grid {
      @include grid(2, 1fr, 30px);
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .trading-plans-page {
    .grid {
      @include grid(3, 1fr, 30px);

      .card {
        &:last-child {
          grid-column: 1/4;
          margin: 0 auto;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
