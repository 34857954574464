.desktop-sidebar {
  display: none;
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .desktop-sidebar {
    @include flex(center, flex-start, column);
    padding: 1.5rem 0;
    border-right: 1px solid $borderColor;

    .links {
      width: 100%;
      margin-top: 2rem;
      padding: 1rem;
      overflow-y: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        font-size: clamp(0.9rem, 3vw, 1rem);
        border-radius: 5px;
        margin-bottom: 1.5rem;
        @include flex(center, flex-start, row);
        color: #fff;
        border: 1px solid transparent;

        &.active {
          background: $linear;
          color: #fff;
        }

        &:hover {
          border: 1px solid $borderColor;
        }

        a {
          @include flex(center, flex-start, row);
          width: 100%;
          padding: 0.8rem 14px;

          svg {
            font-size: 1.3rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
