.customers-reviews-swiper {
  .swiper-slide {
    box-shadow: $boxShadow1;
    background-color: #fff;
    border-radius: 5px;
    padding: 2rem 14px;
    text-align: center;
    @include flex(center, center, column);
    margin-bottom: 2.5rem;

    .quote {
      @include flex(center, center, row);
      margin-bottom: 0.5rem;

      img {
        width: 32px;
        height: 32px;
      }
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
      margin-bottom: 1rem;
      opacity: 0.8;
    }

    .profile-img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-bottom: 0.5rem;

      img {
        border-radius: 50%;
      }
    }

    h4 {
      font-size: clamp(0.8rem, 2vw, 0.9rem);
      color: $primaryGreen;
      font-weight: 400;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
  .customers-reviews-swiper {
    .swiper-slide {
      min-height: 450px;
    }
  }
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
