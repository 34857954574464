// faq
.faq {
  padding: 5rem 14px 5rem 14px;
  @include flex(center, center, column);
  background-color: #f2f2f4;

  .title {
    text-align: center;
    margin-bottom: 2.5rem;

    h4 {
      font-size: clamp(0.9rem, 3vw, 1rem);
      color: $primaryGreen;
    }

    h2 {
      font-size: clamp(1.4rem, 4vw, 2.5rem);
    }
  }

  .q-a {
    @include flex(center, center, column);
    gap: 20px;
    max-width: 900px;
    margin: 0 auto;

    .item {
      @include flex(flex-start, center, column);
      border-bottom: 1px solid #fff;
      padding-bottom: 1rem;
      width: 100%;

      h3 {
        font-size: clamp(1.1rem, 4vw, 1.3rem);
        margin-bottom: 0.5rem;
        font-weight: 500;
        cursor: pointer;
      }

      p {
        font-size: clamp(0.9rem, 3vw, 1rem);
        display: none;
        opacity: 0.6;
        line-height: 26px;
      }

      &.active {
        p {
          display: block;
        }
      }
    }
  }
}

@include for-phone-only {
  .faq {
    padding: 5rem 24px 5rem 24px;
  }
}

@include for-tablet-potrait-up {
  .faq {
    padding: 5rem 40px 5rem 40px;
  }
}

@include for-tablet-landscape-up {
  .faq {
    padding: 5rem 60px 5rem 60px;
  }
}

@include for-desktop-up {
  .faq {
    padding: 5rem 80px 5rem 24px;
  }
}

@include for-big-desktop-up {
}
