.contact-us-page {
  .form-container {
    padding: 4rem 14px;

    form {
      box-shadow: $boxShadow1;
      padding: 1rem 14px 1rem 14px;
      border-radius: 5px 5px 0 0;
      background-color: #fff;

      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        margin-bottom: 1rem;
      }
    }

    .contact-info {
      padding: 2rem 14px;
      background-color: #122272;
      color: #fff;
      border-radius: 0 0 5px 5px;

      .item {
        margin-bottom: 1.5rem;
        @include flex(center, flex-start, row);

        .img {
          width: 38px;
          height: 38px;
          @include flex(center, center, row);
          border-radius: 50%;
          margin-right: 1rem;
          background-color: #fff;

          svg {
            color: #122272;
            font-size: 1.5rem;
          }
        }

        h3 {
          font-size: clamp(1rem, 3vw, 1.2rem);
        }
      }
    }
  }
}

@include for-phone-only {
  .contact-us-page {
    .form-container {
      padding: 4rem 24px;

      form {
        padding: 1rem 24px 1rem 24px;
      }

      .contact-info {
        padding: 2rem 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .contact-us-page {
    .form-container {
      padding: 4rem 40px;

      .contact-info {
        padding: 2rem 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
  .contact-us-page {
    .form-container {
      padding: 4rem 60px;

      .contact-info {
        padding: 2rem 60px;
      }
    }
  }
}

@include for-desktop-up {
  .contact-us-page {
    background-color: #f4f4f4;

    .form-container {
      padding: 4rem 80px;
      @include flex(center, center, row);

      form {
        width: 50%;
        margin-right: 1rem;
        border-radius: 5px;
      }

      .contact-info {
        padding: 2rem 24px;
        border-radius: 5px;
      }
    }
  }
}

@include for-big-desktop-up {
}
