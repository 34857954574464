.newsletter {
    @include flex(center, center, column);
    padding: 3rem 14px;
    text-align: center;
    background-color: #fff;

    .container {
        h3 {
            font-size: clamp(1.1rem, 3vw, 1.3rem);
            margin-bottom: 1rem;
        }

        p {
            font-size: clamp(0.9rem, 3vw, 1rem);
            opacity: 0.8;
        }

        .email-input {
            margin-top: 2rem;
            width: 100%;
            @include flex(center, center, row);
            border-radius: 50px;
            padding: 0.5rem 0.5rem;
            background-color: #eaeff6;

            input {
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                outline: none;
                padding: 0 0.3rem;
            }

            button {
                border-radius: 50px;
            }
        }
    }

}

@include for-phone-only {
    .newsletter {
        padding: 3rem 24px;
    }
}

@include for-tablet-potrait-up {
    .newsletter {
        padding: 3rem 40px;

        .container {
            width: 70%;
        }
    }
}

@include for-tablet-landscape-up {
    .newsletter {
        padding: 3rem 60px;

        .container {
            width: 50%;
        }
    }
}

@include for-desktop-up {
    .newsletter {
        padding: 3rem 80px;
    }
}

@include for-big-desktop-up {}